import classNames from 'classnames';
import * as React from 'react';
import styles from './BackgroundContainer.module.scss';

export interface BackgroundContainerProps {
	className?: string;
	id?: string;
	colorSquare?: boolean;
	children?: React.ReactNode;
	noTopMargin?: boolean;
	noWrapperMargin?: boolean;
	marginTop?: 'xs' | 'sm';
	backgroundColor?: 'default' | 'primaryAccent';
	centerChildren?: boolean;
}

export const BackgroundContainer: React.FC<BackgroundContainerProps> = ({
	className,
	id,
	colorSquare = false,
	children,
	noTopMargin,
	noWrapperMargin,
	marginTop,
	backgroundColor = 'default',
	centerChildren,
}: React.PropsWithChildren<BackgroundContainerProps>) => (
	<div
		className={classNames(
			styles.BackgroundContainer,
			colorSquare ? styles.BackgroundContainer___colorSquare : '',
			{ [styles.BackgroundContainer___noTopMargin]: noTopMargin },
			marginTop && styles[`BackgroundContainer___${marginTop}Margin`],
			styles[`BackgroundContainer___${backgroundColor}`],
			centerChildren && styles.BackgroundContainer___centerChildren,

			className,
		)}
		id={id}
	>
		<div className={classNames(styles.BackgroundContainer_wrapper, noWrapperMargin && styles.BackgroundContainer_wrapper___noWrapperMargin)}>
			{children}
		</div>
	</div>
);
